<template>
  <div class="ajuda-neopag">
    <el-dialog :visible.sync="showFAQDialog" top="5vh">
      <div class="faq">
        <div v-for="(item, index) in faq" :key="index" class="faq__item">
          <h2>{{ item.title }}</h2>
          <p>{{ item.answer }}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showContactDialog" width="350px" top="15vh">
      <div class="contact">
        <h2>
          <small>Email:</small>
          <br />
          <strong>
            <a href="mailto:meajuda@neopag.com">meajuda@neopag.com</a>
          </strong>
        </h2>
        <br />
        <h2>
          <small>Whatsapp:</small>
          <br />
          <strong>
            <a :href="wpHref" target="_blank">
              {{
              "+55 11 94058-4887" | toPhone
              }}
            </a>
          </strong>
        </h2>
      </div>
    </el-dialog>
    <div class="ajuda-neopag__item" @click="handleFAQDialog">
      <div class="icon">
        <i class="el-icon el-icon-info" />
      </div>
      <div class="title">Perguntas frequentes</div>
      <div class="desc">Veja as respostas das perguntas mais comuns dos nossos novos usuários.</div>
    </div>

    <!-- <div class="ajuda-neopag__item" @click="callTutorial">
      <div class="icon">
        <i class="el-icon el-icon-news" />
      </div>
      <div class="title">Boas vindas!</div>
      <div class="desc">
        Caso você tenha perdido ou queira rever a tela de boas vindas para
        conhecer o sistema.
      </div>
    </div> -->

    <div class="ajuda-neopag__item" @click="handleInfoDialog">
      <div class="icon">
        <i class="el-icon el-icon-service" />
      </div>
      <div class="title">Contato</div>
      <div class="desc">Caso ainda tenha dúvidas entre em contato com a Neopag.</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import phone from "@/helpers/phone";

export default {
  filters: {
    toPhone: phone.format
  },
  data() {
    return {
      showFAQDialog: false,
      showContactDialog: false
    };
  },
  computed: {
    ...mapGetters({
      faq: types.STORE_FAQ,
      storeDetails: types.AUTH_ACTIVE_STORE_DETAILS
    }),
    formattedAddress() {
      const address = this.storeDetails.address;
      let str = "";
      if (address.street) str += address.street;
      if (address.number) str += ", " + address.number;
      if (address.cep)
        str += " " + address.cep.replace(/(\d{5})(\d{3})/, "$1-$2");
      if (address.uf) str += " " + address.uf;
      if (address.city) str += " " + address.city;
      return str.trim();
    },
    wpHref() {
      const text = `Olá Neopag, a ${this.storeDetails.name} solicita sua ajuda!`.replace(
        " ",
        "%20"
      );
      const url = "https://api.whatsapp.com/send?phone=5511940584887&text=";
      return url + text;
    }
  },
  mounted() {
    this.$store.dispatch(types.STORE_FAQ).catch(error => {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    });
  },
  methods: {
    async handleFAQDialog() {
      await this.$store.dispatch(types.STORE_FAQ).catch(error => {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      });
      this.showFAQDialog = true;
    },
    // callTutorial() {
    //   this.$bus.$emit("show-welcome");
    // },
    handleInfoDialog() {
      if (this.$isDev) {
        this.showContactDialog = true;
      } else {
      }
    }
  }
};
</script>

<style lang="scss">
.ajuda-neopag {
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;

  &__item {
    flex: 0 auto;
    width: 240px;
    height: 200px;
    background: #fff;
    padding: 1rem;
    text-align: center;
    border-radius: 3px;
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 3rem;

    @include box-shadow(1);
    opacity: 0.75;
    transition: opacity 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      cursor: pointer;
      opacity: 1;
      @include box-shadow(2);
    }

    &:active {
      @include box-shadow(1);
    }

    .icon {
      flex: 0 40px;
      font-size: 40px;
      line-height: 40px;
      color: $laranja;
      margin-top: 0.5rem;
    }

    .title {
      margin: 1rem 0 0.75rem;
      flex: 0 30px;
      font-family: $title-font;
      line-height: 30px;
      font-weight: 500;
    }

    .desc {
      flex: 0 auto;
      font-size: 13px;
      line-height: 1.55;
      font-family: $title-font;
      max-width: 280px;
      font-style: italic;
      margin: 0 auto;
      color: $preto;
    }
  }
}
.faq {
  &__item {
    margin-bottom: 2rem;
    h2 {
      margin-bottom: 0.5rem;
      line-height: 1.45;
      font-size: 22px;
      font-weight: bold;
      color: $laranja;
    }
    p {
      white-space: pre-wrap;
      line-height: 1.2rem;
    }
  }
}

.contact {
  small {
    font-weight: bold;
    margin-bottom: 1rem;
  }
 a {
   color: $laranja;
   text-decoration: none;
   &:hover {
     color: $preto;
     text-decoration: underline;
   }
 }
}
</style>
